import { nextPage } from "../store/page/actions";
import { addMessages } from "../store/conversation/actions";
import { addConversationAttendant } from "../store/conversation/actions";

import store from "../store";
const CONVERSATION = "Conversation";

export default function conversaNaoAtendida(event) {
  let now = new Date();
  let newMessages = [];
  localStorage.setItem("conversationAttendant", false);
  if (event.mensagens.length > 0) {
    newMessages = event.mensagens.map((event) => {
      if (!event.hora_da_mensagem) {
        return { ...event, hora_da_mensagem: now };
      } else {
        return event;
      }
    });
  }

  store.dispatch(addConversationAttendant());
  store.dispatch(nextPage(CONVERSATION));
  store.dispatch(addMessages(newMessages));
}
