import { apiHttpNotOpen } from "./api-http";

class GetConfigHttpWithCache {
  static getInstance() {
    if (!this.instance) {
      this.instance = new GetConfigHttpWithCache();
    }
    return this.instance;
  }

  async getConfig() {
    if (!this.configWebChat) {
      const response = await apiHttpNotOpen.get(
        "api/open/configurations/webchat"
      );

      const useBot = response.data.data.ativado;
      const botName = response.data.data.nome_atendente;
      const color = response.data.data.cor || "#2C984A";

      const config = {
        color,
        useBot,
        botName,
        colorHyperlink: response.data.data.hyperLinkColor || "#2C984A",
        typeOfOpeningChat: response.data.data.typeOfOpeningChat || "ICON",
        nameOfOpeningChat: response.data.data.nameOfOpeningChat,
        imageOfOpeningChat: response.data.data.imageOfOpeningChat,
        iconOfOpeningChat: response.data.data.iconOfOpeningChat,
        backgroundBallonAttendant: response.data.data.backgroundBallonAttendant,
        backgroundBallonClient: response.data.data.backgroundBallonClient,
        textColorBallonAttendant: response.data.data.textColorBallonAttendant,
        textColorBallonClient: response.data.data.textColorBallonClient,
        textContrastColor: response.data.data.textContrastColor || "#fff",
        colorButtonLogin: response.data.data.backgroundButtonLogin || color,
        logoChat: response.data.data.logoChat,
        profilePic: response.data.data.profilePic,
      };

      this.configWebChat = config;
      return config;
    }
    return this.configWebChat;
  }
}

export default GetConfigHttpWithCache.getInstance();
