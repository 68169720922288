import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  G4Container,
  G4FullWindow,
  G4LogoOpen,
  ImageOpen,
} from "./components/styled";
import End from "./pages/end/End";
import "./config/ReactotronConfig";
import Wait from "./pages/wait/Wait";
import Login from "./pages/login/Login";
import Queue from "./pages/queue/Queue";
import { subscribe } from "./util/events";
import { FaComments } from "react-icons/fa";
import Header from "./components/header/Header";
import { WebchatContext } from "./context/WebchatContext";
import Conversation from "./pages/conversation/Conversation";
import CustumerExperienceSurvey from "./pages/customer-experience-survey/CustumerExperienceSurvey";
import { G4LoginImg, G4LoginImgDiv } from "./pages/login/components/styled";
import GetConfigHttpWithCache from "./services/get-config-chat";
import LoadingDots from "./components/Loading/Loading";

const LOGIN = "Login";
const QUEUE = "Queue";
const WAIT = "Wait";
const CONVERSATION = "Conversation";
const CUSTOMER_EXPERIENCE = "CustomerExperience";
const END = "End";

const App = () => {
  const page = useSelector((state) => state.page);
  const { modeWebchatFull, isMobile } = useContext(WebchatContext);
  const [chatOpen, setChatOpen] = useState(isMobile ? true : false);
  const [showChat, setShowChat] = useState(false);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    GetConfigHttpWithCache.getConfig().then(async (response) => {
      setConfig(response);
      setShowChat(true);
    });
  }, []);

  const style = isMobile
    ? {
        zIndex: 20000,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : { zIndex: 20000 };

  subscribe("openWebChat", () => openChat());

  function openChat() {
    if (showChat) setChatOpen(true);
  }

  function minimize(value) {
    setChatOpen(value);
  }

  const getTypeOfOpeningChat = () => {
    if (showChat) {
      if (config?.typeOfOpeningChat === "ICON" && config.iconOfOpeningChat) {
        return <ImageOpen src={config.iconOfOpeningChat} alt="logo" />;
      } else if (
        config?.typeOfOpeningChat === "ICON" &&
        !config.iconOfOpeningChat
      ) {
        return (
          <FaComments
            onClick={() => {
              openChat();
            }}
            size="40"
            color="#fff"
          />
        );
      } else if (config?.typeOfOpeningChat === "TEXT") {
        return config?.nameOfOpeningChat;
      }
    }
  };

  return (
    <>
      {config && showChat ? (
        modeWebchatFull ? (
          <G4FullWindow>
            <G4LoginImgDiv isMobile={isMobile}>
              <G4LoginImg src={config.logoChat} alt="logo" />
            </G4LoginImgDiv>

            <G4Container
              isMobile={isMobile}
              page={page}
              modeWebchatFull={modeWebchatFull}
              style={{ zIndex: 20000 }}
              openDisplay={true}
            >
              <Header config={config} />
              {page === LOGIN ? <Login config={config} /> : ""}
              {page === QUEUE ? <Queue config={config} /> : ""}
              {page === WAIT ? <Wait config={config} /> : ""}
              {page === CONVERSATION ? <Conversation config={config} /> : ""}
              {page === CUSTOMER_EXPERIENCE ? (
                <CustumerExperienceSurvey config={config} />
              ) : (
                ""
              )}
              {page === END ? <End config={config} /> : ""}
            </G4Container>
          </G4FullWindow>
        ) : (
          <div style={style}>
            {!chatOpen && config.color !== "" && (
              <G4LogoOpen
                color={config.color}
                textContrastColor={config.textContrastColor}
                style={{ zIndex: 20000 }}
                onClick={() => {
                  openChat();
                }}
                isIcon={config?.typeOfOpeningChat === "ICON"}
                isImage={config?.typeOfOpeningChat === "IMAGE"}
                isText={config?.typeOfOpeningChat === "TEXT"}
                logoURL={config?.imageOfOpeningChat}
              >
                {getTypeOfOpeningChat()}
              </G4LogoOpen>
            )}

            <G4Container
              isMobile={isMobile}
              style={{ zIndex: 20000 }}
              openDisplay={chatOpen}
            >
              <Header minimize={minimize} config={config} />
              {page === LOGIN ? <Login config={config} /> : ""}
              {page === QUEUE ? <Queue config={config} /> : ""}
              {page === WAIT ? <Wait config={config} /> : ""}
              {page === CONVERSATION ? <Conversation config={config} /> : ""}
              {page === CUSTOMER_EXPERIENCE ? (
                <CustumerExperienceSurvey config={config} />
              ) : (
                ""
              )}
              {page === END ? <End config={config} /> : ""}
            </G4Container>
          </div>
        )
      ) : (
        <G4LogoOpen
          color="#ccc"
          textContrastColor={"#000"}
          style={{ zIndex: 20000 }}
          isIcon={true}
          isImage={false}
          isText={false}
          logoURL={""}
        >
          <LoadingDots />
        </G4LogoOpen>
      )}
    </>
  );
};

export default App;
