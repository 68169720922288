import React from "react";
import { useSelector } from "react-redux";
import "./components/Wait.css";
import { G4ContainerWait, G4WaitImg, G4Footer } from "./components/styled";
import { FaHourglassHalf } from "react-icons/fa";
import Loader from "../../components/loader";

const Wait = ({ config }) => {
  const user = useSelector((state) => state.user);
  const hours = useSelector((state) => state.hoursOfOut);

  return (
    <G4ContainerWait>
      <G4WaitImg src={config.logoChat} />

      {!hours ? (
        <div className="textContent">
          {user.positionQueue === 0 && user.positionQueue === 0 ? (
            <>
              <Loader config={config.color} />
              Transferindo para atendente
            </>
          ) : (
            <>
              <strong>
                {user.positionQueue &&
                  `Olá, 
              ${user.name},`}
              </strong>
              {user.positionQueue &&
                `você está na posição ${user.positionQueue} na fila atendimento`}
            </>
          )}
        </div>
      ) : (
        <>
          <FaHourglassHalf
            size={23}
            color="#24233a"
            className="iconNotAtendent"
          />
          <label className="NotAtendent">Atendimento fora de horário</label>
        </>
      )}

      <G4Footer color={config.color}></G4Footer>
    </G4ContainerWait>
  );
};

export default Wait;
