import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  G4ContainerCustomerExperience,
  G4FormCustomerExperience,
  G4CustomerExperienceImgDiv,
  G4CustomerExperienceImg,
  G4TextAreaCustomerExperience,
  G4ButtonCustomerExperience,
  G4TextAriaLabel,
  G4CenteredDiv,
  G4QuestionTag,
} from "./components/styled";
import "./components/SurveyRating.css";
import { nextPage } from "../../../../store/page/actions";
import MultipleSelectChips from "@bit/lubuskie.material-ui.multiple-select-chips";
import SurveyService from "../../service";
import FlexRating from "./components/FlexRating";

const END = "End";

const SurveyRating = ({ conversationId, surveyConfig, config }) => {
  const dispatch = useDispatch();
  const [optionTags, setOptionTags] = useState(null);
  const [questionTag, setQuestionTag] = useState("");
  const [rate, setRate] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [observation, setObservation] = useState("");

  useEffect(() => {
    let aux = false;
    setSelectedTags([]);

    surveyConfig.answers.forEach((el) => {
      if (el["rate"] === rate) {
        let arrayTag = [];
        el["tags"] &&
          el["tags"].forEach((tag) => {
            arrayTag.push({ label: tag.name, value: tag });
          });
        setOptionTags(arrayTag);
        setQuestionTag(el.question_tag);
        aux = true;
      }
    });

    if (rate === null || aux === false) {
      setQuestionTag(null);
      setOptionTags(null);
    }
  }, [rate, surveyConfig]);

  const npsSubmit = (event) => {
    event.preventDefault();
    if (rate < 1) {
    } else {
      const formatedData = {
        channel: "chat",
        observation: observation,
        rate: rate,
        type_survey: surveyConfig.type_survey,
        tags: [...selectedTags],
        name: surveyConfig.name,
      };

      SurveyService.sendInquiry(conversationId, surveyConfig._id, formatedData)
        .then((res) => {
          dispatch(nextPage(END));
        })
        .catch((err) => {
          console.log(err.response.data.message || "Error!");
        });
    }
  };

  const handleMessageChange = (event) => {
    setObservation(event.target.value);
  };

  return (
    <G4ContainerCustomerExperience>
      <G4CustomerExperienceImgDiv>
        <G4CustomerExperienceImg alt="logo" src={config.logoChat} />
      </G4CustomerExperienceImgDiv>

      <G4FormCustomerExperience>
        <G4CenteredDiv>
          <h4>{surveyConfig.question}</h4>
        </G4CenteredDiv>

        <FlexRating
          setRate={setRate}
          rate={rate}
          type={surveyConfig.type_survey}
        />

        <G4QuestionTag>
          {optionTags && optionTags.length > 0 && <h5>{questionTag}</h5>}
        </G4QuestionTag>
        <div>
          {optionTags && (
            <MultipleSelectChips
              label=""
              value={selectedTags}
              setValue={setSelectedTags}
              options={optionTags}
            />
          )}
        </div>

        <G4TextAriaLabel htmlFor="textId">
          <h4>Deseja adicionar algum comentário?</h4>
        </G4TextAriaLabel>
        <G4TextAreaCustomerExperience
          id="textId"
          name="text"
          rows="4"
          cols="40"
          value={observation}
          onChange={handleMessageChange}
          placeholder="Observação..."
        />
        <G4ButtonCustomerExperience onClick={npsSubmit} color={config.color}>
          Enviar
        </G4ButtonCustomerExperience>
      </G4FormCustomerExperience>
    </G4ContainerCustomerExperience>
  );
};

export default SurveyRating;
